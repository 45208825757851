<template>
  <div class="container">
    <AudioIcon />
    <h3 class="head">{{ headTitle }}</h3>
    <div class="content">
      <div class="member animate__animated animate__fadeIn delay-500ms">
        <img src="@game/assets/img/level05/frank.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ frank }}</div>
          <div class="text">{{ frankText1 }}</div>
        </div>
      </div>
      <div class="member animate__animated animate__fadeIn delay-1">
        <img src="@game/assets/img/level05/frank.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ frank }}</div>
          <div class="text"><img src="@game/assets/img/level05/screenshot-1.png" alt="" /></div>
        </div>
      </div>
      <div class="member animate__animated animate__fadeIn delay-2">
        <img src="@game/assets/img/level05/charles.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ charles }}</div>
          <div class="text">{{ charlesText }}</div>
        </div>
      </div>
      <div class="member animate__animated animate__fadeIn delay-3">
        <img src="@game/assets/img/level05/frank.png" alt="" class="avatar">
        <div class="info">
          <div class="title">{{ frank }}</div>
          <div class="text">{{ frankText2 }}</div>
        </div>
      </div>
    </div>
    <div class="footer" @click="onForward">
      <img src="@game/assets/img/level05/reply-box.png" alt="">
    </div>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene4',
  mixins: [audioMixinLevel5],
  data() {
    return {
      headTitle: tLevel5('headTitle'),
      frank: tLevel5('members.frank'),
      charles: tLevel5('members.charles'),
      frankText1: tLevel5('scene8.frankText1'),
      frankText2: tLevel5('scene8.frankText2'),
      charlesText: tLevel5('scene8.charlesText'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L5Scene9' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-4-bg.jpg);
  background-size: cover;
  ;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  .head {
    width: 100%;
    height: 60px;
    background-color: #8fcef5;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    color: #1d3082;
    font-weight: normal;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    box-sizing: border-box;
    width: 100%;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 30px;

    .member {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;

      img {
        margin-top: -5px;
        width: 50px;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: flex-end;
        gap: 10px;

        .title {
          color: #aad8f7;
        }

        .text {
          box-sizing: border-box;
          background-color: #afdffb;
          color: #334b94;
          padding: 10px 20px;
          border-radius: 10px;
        }
      }
    }

    .member:nth-child(2)>img {
      visibility: hidden;
    }

    .member:nth-child(2) .text {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 15px;

      img {
        width: 150px;
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 100%;
    }

    &:hover {
      opacity: .8;
    }
  }
}
</style>