<template>
  <div class="container">
    <AudioIcon />
    <h3 class="head">{{ headTitle }}</h3>
    <div class="content">
      <h3 class="title">{{ title }}</h3>
      <div class="persons">
        <div class="person animate__animated animate__fadeIn delay-500ms">
          <img src="@game/assets/img/level05/charles.png" alt="">
          <span>{{ charles }}</span>
        </div>
        <div class="person animate__animated animate__fadeIn delay-1">
          <img src="@game/assets/img/level05/frank.png" alt="">
          <span>{{ frank }}</span>
        </div>
        <div class="person animate__animated animate__fadeIn delay-2">
          <img src="@game/assets/img/level05/girami.png" alt="">
          <span>{{ girami }}</span>
        </div>
        <div class="person animate__animated animate__fadeIn delay-3">
          <img src="@game/assets/img/level05/a.png" alt="">
          <span>{{ a }}</span>
        </div>
        <div class="person animate__animated animate__fadeIn delay-4">
          <img src="@game/assets/img/level05/b.png" alt="">
          <span>{{ b }}</span>
        </div>
        <div class="person animate__animated animate__fadeIn delay-5">
          <img src="@game/assets/img/level05/c.png" alt="">
          <span>{{ c }}</span>
        </div>
      </div>
      <div class="text-container">
        <p class="text">{{ text }}</p>
        <div class="button" @click="onForward">{{ buttonText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';
import createGroupSound from '@game/assets/media/level05/create-group.mp3';

export default {
  name: 'Scene3',
  mixins: [audioMixinLevel5],
  mounted() {
    this.$audioManager.play('other', createGroupSound)
  },
  data() {
    return {
      headTitle: tLevel5('headTitle'),
      title: tLevel5('scene3.title'),
      text: tLevel5('scene3.text'),
      buttonText: tLevel5('scene3.buttonText'),
      charles: tLevel5('members.charles'),
      frank: tLevel5('members.frank'),
      girami: tLevel5('members.girami'),
      a: tLevel5('members.a'),
      b: tLevel5('members.b'),
      c: tLevel5('members.c'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L5Scene4' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-3-bg.jpg);
  background-size: cover;
  ;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;


  .head {
    width: 100%;
    height: 60px;
    background-color: #8fcef5;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    color: #1d3082;
    font-weight: normal;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
      margin: 0;
      color: #b0dffc;
    }

    .persons {
      margin: 30px 0;
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      gap: 20px;

      .person {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 60px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        img {
          width: 60px;
        }

        span {
          text-align: center;
          color: #fff;
        }
      }
    }


    .text-container {
      box-sizing: border-box;
      padding: 15px 15px;
      width: 80%;
      background-color: #305c8f;
      color: #b0dffc;
      border: 2px solid #b0dffc;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .text {
        margin: 0;
        padding: 0;
        text-align: justify;
      }

      .button {
        margin-top: 20px;
        background-color: #fff;
        padding: 5px 10px;
        color: #2d56a0;
        border-radius: 5px;
        cursor: pointer;
      }

      .button:hover {
        opacity: .8;
      }
    }
  }
}
</style>