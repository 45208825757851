<template>
    <div class="container">
        <AudioIcon />
        <h1 class="title">{{ title }}</h1>
        <div class="description-container">
            <ul>
                <li v-for="(item, index) in descriptionList" :key="index">{{ item }}</li>
            </ul>
            <div class="button" @click="continueGame">{{ buttonText }}</div>
        </div>
    </div>
</template>

<script>
import { tLevel1 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene20',
    mixins: [audioMixinLevel1],
    data() {
        return {
            title: tLevel1('scene20.title'),
            description: tLevel1('scene20.description'),
            descriptionList: tLevel1('scene20.descriptionList'),
            buttonText: tLevel1('scene20.buttonText'),
        }
    },
    methods: {
        continueGame() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L1Scene21' })
            })
        },
    }
}

</script>

<style lang="scss" scoped>
.container {
    /* animation */
    position: absolute;

    width: 100%;
    height: 100%;

    background-image: url(@game/Level01/assets/img/kepu.jpg);
    background-position: center;
    background-size: cover;
    ;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        box-sizing: border-box;
        position: absolute;
        top: 5%;
        border: 2px solid #fff;
        border-radius: 25px;
        max-width: 95%;
        background-color: #50a459;
        color: #fff;
        font-size: 1.2rem;
        font-weight: normal;
        padding: 5px 20px;
        text-align: center;
    }

    .description-container {
        position: absolute;
        top: 15%;
        bottom: 15%;
        width: 65%;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        ul {
            list-style: decimal;
            margin: 0;
            padding: 0;
            padding-left: 1.2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: .5rem;
        }

        .button {
            max-width: 90%;
            background-color: #50a459;
            border-radius: .5rem;
            text-align: center;
            color: #fff;
            font-size: 1rem;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }


}
</style>