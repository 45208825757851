<template>
  <div class="container">
    <AudioIcon />
    <div class="text-container-l5-s4">
      <p>{{ text[0] }}<span @click="onForward">{{ text[1] }}</span>{{ text[2] }}</p>
    </div>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene14',
  mixins: [audioMixinLevel5],
  data() {
    return {
      text: tLevel5('scene14.text'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        // this.$router.push({ name: 'L5Scene9', params: { isShow: true } })
        this.$router.push({ name: 'L5Scene4', params: { isShow: true } })
      })
    }
  }
}
</script>

<style scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-14-bg.jpg);
  background-size: cover;
  ;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.text-container-l5-s4 {
  width: 83%;
  margin-bottom: 5rem;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 1rem;
  border: 2px solid #78ac75;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  margin: 0;
  padding: 25px;
  padding-bottom: 15px;
  text-align: justify;
}

span {
  color: #275edf;
  cursor: pointer;
}

span:hover {
  opacity: .8;
}
</style>