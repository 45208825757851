<template>
    <div class="container">
        <AudioIcon />
        <div class="container-text">
            <p>{{ text }}</p>
            <div class="form-container">
                <input type="text" v-model="password">
                <div class="button" @click="onClick">{{ buttonText }}</div>
            </div>
        </div>
        <div class="tooltip">
            <img src="@game/Level01/assets/img/bell.png" alt="">
            {{ tooltip }}
        </div>
        <transition appear enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut">
            <PasswordErrorBox v-if="isPasswordError" :dialogTip="dialogText" :buttonText="dialogButtonText"
                :closeCallback="() => isPasswordError = false" />
        </transition>
    </div>
</template>

<script>
import 'animate.css';
import { tLevel1 } from '@game/utils';
import warning from '@game/assets/media/level01/warning.mp3';
import PasswordErrorBox from '@game/Level01/components/PasswordErrorBox.vue';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene15',
    mixins: [audioMixinLevel1],
    components: { PasswordErrorBox },
    data() {
        return {
            password: 'Queen',
            text: tLevel1('scene15.shylock'),
            buttonText: tLevel1('scene15.buttonText'),
            tooltip: tLevel1('scene15.tooltip'),
            dialogText: tLevel1('scene15.dialogText'),
            dialogButtonText: tLevel1('scene15.dialogButtonText'),
            isPasswordError: false,
        }
    },
    methods: {
        onClick() {
            this.$audioManager.playButtonNext();
            if (this.password.toLowerCase().includes('queen77live')) {
                this.$store.commit('completeTask')
                this.$router.push({ name: 'L1Scene16' })
            } else {
                this.isPasswordError = true;
                this.$audioManager.play('error', warning)
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.container {
    /* animation */
    position: absolute;

    width: 100%;
    height: 100%;
    background-image: url(@game/Level01/assets/img/shylock-1.jpg);
    background-position: center;
    background-size: cover;
    ;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container-text {
        box-sizing: border-box;
        position: absolute;
        bottom: 10%;
        width: 83%;
        background-color: #fff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 12%;

        p {
            margin: 0;
        }

        .form-container {
            max-width: 100%;
            display: flex;

            input,
            input:focus {
                border: none;
                border-bottom: 2px solid #5f857a;
                outline: none;
            }

            input {
                // width: 6rem;
                font-size: 1rem;
            }

            .button {
                margin-left: 1rem;
                letter-spacing: 0.2rem;
                background-color: #50a459;
                color: #fff;
                border-radius: .5rem;
                text-align: center;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .tooltip {
        position: absolute;
        bottom: 5%;
        max-width: 95%;
        font-size: 0.7rem;
        padding: .3rem .5rem;
        color: #848a77;
        font-weight: normal;
        background-color: #fdf7c1;
        border-radius: 1rem;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 1rem;
            margin-right: .5rem;
        }
    }
}
</style>