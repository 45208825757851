<template>
    <div class="overlay" @click.stop="() => { }">
        <div class="dialog" :class="{ 'all-selected': isAllSelected }">
            <img src="./assets/img/mail.png" alt="">
            <div class="wrapper">
                <p>{{ text[0] }}</p>
                <p>{{ text[1] }}</p>
            </div>
            <div class="button" @click="viewAnswer">{{ buttonText }}</div>
        </div>
    </div>
</template>

<script>
import { tLevel2 } from '../../utils';


export default {
    name: 'Dialog',
    props: {
        isAllSelected: {
            type: Boolean,
            required: true,
        },
        viewAnswer: {
            type: Function,
            required: true,
        }
    },
    data() {
        return {
            text1: tLevel2('scene4.dialog.text1'),
            text2: tLevel2('scene4.dialog.text2'),
            buttonText: tLevel2('scene4.dialog.buttonText'),
        }
    },
    computed: {
        text() {
            if (this.isAllSelected) {
                return this.text2
            }
            return this.text1;
        }
    },
}
</script>

<style scoped>
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000;
}

.dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 300px;
    height: 300px;
    background-image: url(./assets/img/dialog-bg-red.png);
    background-position: center center;
    background-size: cover;
    ;
    background-repeat: no-repeat;
    z-index: 1001;


    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.dialog img {
    height: 100px;
}

.all-selected {
    background-image: url(./assets/img/dialog-bg-yellow.png);
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    max-width: 90%;
}

p {
    margin: 0;
}

.button {
    box-sizing: border-box;
    padding: 5px 10px;
    background-color: #66a261;
    color: #fff;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.button:hover {
    opacity: .8;
}
</style>