import Vue from "vue";
import Vuex from "vuex";
// import audioManager from '../views/game/AudioManager';
// import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const TIME_OUT = 120;

const actions = {
  addLevel(context, level) {
    context.commit("ADD_LEVEL", level);
  },

  setTime(context, time) {
    context.commit("SET_TIME", time);
  },

  setProgress(context, progress) {
    context.commit("SET_PROGRESS", progress);
  },

  playAudio({ commit, state }, { audioSrc }) {
    if (state.audio.currentAudioSrc === audioSrc) {
      if (!state.audio.isPlaying) {
        // audioManager.play('bg', audioSrc, true);
        commit("setPlaying", true);
      }
      return;
    }
    // audioManager.play('bg', audioSrc, true);
    commit("setCurrentAudio", { audioSrc });
  },
};

const mutations = {
  ADD_LEVEL(state, level) {
    state.availableLevels.push(level);
    state.availableLevels = [...new Set(state.availableLevels)];
  },

  SET_TIME(state, time) {
    state.level3.time = time;
  },

  SET_PROGRESS(state, progress) {
    state.level3.progress = progress;
  },

  resetTime(state) {
    state.level3.time = TIME_OUT;
  },

  completeTask(state) {
    state.taskCompleted = true;
  },
  resetTask(state) {
    state.taskCompleted = false;
  },
  setCurrentScene(state, scene) {
    state.currentScene = scene;
  },
  setForceNavigate(state, isForce) {
    state.forceNavigate = isForce;
  },
  setMasterAudioVolume(state, volume) {
    state.audio.masterVolume = volume;
  },

  setCurrentAudio(state, { audioSrc }) {
    state.audio.currentAudioSrc = audioSrc;
  },
};

const state = {
  // availableLevels: [1, 2, 3, 4, 5],
  availableLevels: [1],
  level3: {
    time: TIME_OUT,
    score: 250,
    progress: 10,
  },
  taskCompleted: false,
  currentScene: "",
  forceNavigate: false,
  audio: {
    masterVolume: 0,
    currentAudioSrc: null,
  },
};

export default new Vuex.Store({
  actions,
  mutations,
  state,
  // plugins: [createPersistedState()],
});
