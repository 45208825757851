<template>
  <div class="container" @click="otherSelected">
    <AudioIcon />
    <h3 class="title">{{ title }}</h3>
    <div class="card">
      <div class="card-title">{{ cardTitle }}</div>
      <div class="card-content">
        <div class="mail-head">
          <img class="avatar" :src="avatarImg" alt="" />
          <div class="content">
            <div class="mail-title">{{ mailTitle }}</div>
            <div class="mail-sender">{{ mailSender }}</div>
          </div>
        </div>
        <!-- <CustomScrollbar :scrollUpdate="scrollUpdate"> -->
        <div class="mail-content" @scroll="handleScroll" @touchmove="handleScroll">
          <template v-for="(item, index) in mailText">
            <p v-if="index === 0" :key="index" @click="mailTitlePlusClick">{{ item }}<img class="plus"
                :src="mailTitlePlus" /></p>
            <p v-else-if="index === 2" :key="index" @click="mailTwoFactorPlusClick">
              {{ item[0] }}<span>{{ item[1] }}</span><img class="plus" :src="mailTwoFactorPlus" />
            </p>
            <p v-else-if="index === 4" :key="index" @click="mailAuthenticationPlusClick">
              {{ item[0] }}<span>{{ item[1] }}</span>{{ item[2] }}<img class="plus" :src="mailAuthenticationPlus" />
            </p>
            <p v-else-if="index === 6" :key="index">
            <ul @click="mailOnlinePayPlusClick">
              <template v-for="(v, k) in item">
                <li v-if="k === 3" :key="k">{{ v }} <img class="plus" :src="mailOnlinePayPlus" /></li>
                <li v-else :key="k">{{ v }}</li>
              </template>
            </ul>
            </p>
            <p v-else :key="index">{{ item }}</p>
          </template>
        </div>
        <!-- </CustomScrollbar> -->
        <div :class="['button', isNoneSelected ? 'button-disable' : '']" @click="onSubmit">{{ buttonText }}
        </div>
      </div>
    </div>
    <transition appear enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
      <Dialog v-if="isShowDialog" :isAllSelected="isAllSelected" :viewAnswer="onForward" />
    </transition>
  </div>
</template>

<script>
// import CustomScrollbar from '@game/Level02/components/CustomScrollbar.vue';
import Dialog from '@game/Level02/components/Dialog.vue';
import {
  tLevel2
} from '@game/utils';
import {
  audioMixinLevel1
} from '@game/mixins/audioMixin.js';

import avatar from '@game/Level02/assets/img/avatar.png';
import plusDisable from '@game/Level02/assets/img/plus-disable.png';
import plusEnable from '@game/Level02/assets/img/plus-enable.png';
import scrollSound from '@game/assets/media/level02/scroll.mp3';
import selectMailSound from '@game/assets/media/level02/select-mail.mp3';
import selectErrorSound from '@game/assets/media/level01/select-error.wav';

export default {
  name: 'Scene9',
  mixins: [audioMixinLevel1],
  components: {
    // CustomScrollbar,
    Dialog,
  },
  data() {
    return {
      title: tLevel2('scene9.title'),
      cardTitle: tLevel2('scene4.cardTitle'),
      mailTitle: tLevel2('scene9.mailTitle'),
      mailSender: tLevel2('scene9.mailSender'),
      mailTitlePlus: plusDisable,
      mailTwoFactorPlus: plusDisable,
      mailAuthenticationPlus: plusDisable,
      mailOnlinePayPlus: plusDisable,
      mailText: tLevel2('scene9.mailText'),
      buttonText: tLevel2('scene4.buttonText'),
      isShowDialog: false,
      avatarImg: avatar,
      debounceTimer: null,
    };
  },
  methods: {
    mailTitlePlusClick(event) {
      event.stopPropagation();
      this.mailTitlePlus = this.mailTitlePlus === plusDisable ? plusEnable : plusDisable;
    },
    mailTwoFactorPlusClick(event) {
      event.stopPropagation();
      this.mailTwoFactorPlus = this.mailTwoFactorPlus === plusDisable ? plusEnable : plusDisable;
    },
    mailAuthenticationPlusClick(event) {
      event.stopPropagation();
      this.mailAuthenticationPlus = this.mailAuthenticationPlus === plusDisable ? plusEnable : plusDisable;
    },
    mailOnlinePayPlusClick(event) {
      event.stopPropagation();
      this.mailOnlinePayPlus = this.mailOnlinePayPlus === plusDisable ? plusEnable : plusDisable;
    },
    otherSelected() { },
    onForward(event) {
      event.stopPropagation();
      this.$store.commit('completeTask');
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L2Scene10' })
      });
    },
    onSubmit(event) {
      event.stopPropagation();
      this.isShowDialog = true;
      if (this.isAllSelected) {
        this.$audioManager.play('other', selectMailSound);
      } else {
        this.$audioManager.play('other', selectErrorSound);
      }
    },
    scrollUpdate() {
      this.$audioManager.play('other', scrollSound);
    },
    handleScroll() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.$audioManager.play('other', scrollSound);
      }, 100);
    },
  },
  computed: {
    isNoneSelected() {
      return this.mailTitlePlus === plusDisable &&
        this.mailTwoFactorPlus === plusDisable &&
        this.mailAuthenticationPlus === plusDisable &&
        this.mailOnlinePayPlus === plusDisable;
    },
    isAllSelected() {
      return this.mailTitlePlus === plusEnable &&
        this.mailTwoFactorPlus === plusEnable &&
        this.mailAuthenticationPlus === plusEnable &&
        this.mailOnlinePayPlus === plusEnable;
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(@game/Level02/assets/img/mail-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #66a261;
    border: .25rem solid #fff;
    border-radius: 1rem;
    color: #fff;
    padding: .3rem 1rem;
    margin: 0;
    margin-top: 6%;
  }

  .card {
    margin-top: 8%;
    width: 90%;
    height: 80%;
    border-radius: 1.5rem;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .plus {
      width: 1.2rem;
      /* #ifdef MP-WEIXIN */
      height: 1.2rem;
      /* #endif */
    }

    .card-title {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4a4a4a;
      height: 3rem;
      color: #d7cd81;
      width: 100%;
      margin-bottom: 30px;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 1rem 0;
      padding-right: .25rem;
      height: 80%;

      .mail-head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0;
        padding-left: 2.5rem;
        padding-bottom: 1.5rem;
        width: 100%;

        .avatar {
          width: 2.5rem;
          height: 2.5rem;
        }

        .content {
          margin-left: .7rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .mail-title {
            font-size: .8rem;
            font-weight: bold;
            letter-spacing: 1px;
          }

          .mail-sender {
            font-size: .7rem;
            position: relative;

            span {
              cursor: pointer;
            }
          }

          .plus {
            right: -1.5rem;
            cursor: pointer;
          }
        }
      }

      .mail-content {
        // height: 300px;
        // max-height: 40%;
        max-height: 80%;
        padding: 1rem 1rem;
        padding-top: 0;
        font-weight: bold;
        letter-spacing: 1px;
        overflow-y: auto;

        p {
          font-size: .8rem;
          margin-top: 1rem;
          margin-left: .5rem;

          &.first-child {
            margin-top: 0;
            cursor: pointer;
          }

          img {
            vertical-align: bottom;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          padding-left: .66rem;
          cursor: pointer;

          li img {
            vertical-align: bottom;
          }
        }
      }

      .button {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background-color: #50a459;
        border-radius: .5rem;
        color: #fff;
        cursor: pointer;

        &:hover {
          opacity: .8;
        }
      }

      .button-disable {
        pointer-events: none;
        background-color: #d9d9d9;
        color: #000;
      }
    }
  }
}
</style>