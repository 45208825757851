<template>
    <div class="container">
        <AudioIcon />
        <div class="container-text">
            <p>{{ text }}</p>
            <img class="forward" src="@game/assets/img/arrow.png" alt="" @click="onForward">
            <img class="backward" src="@game/assets/img/arrow.png" alt="" @click="onBackward">
        </div>
    </div>
</template>

<script>
import { tLevel1 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene23',
    mixins: [audioMixinLevel1],
    data() {
        return {
            text: tLevel1('scene23.text')
        }
    },
    methods: {
        onForward() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L1Scene24' })
            })
        },
        onBackward() {
            this.$audioManager.playButtonPrevious(() => {
                this.$router.go(-1)
            })
        }
    }
}

</script>  

<style lang="scss" scoped>
.container {
    background-image: url(@game/Level01/assets/img/alex-1.jpg);

    .container-text {
        min-height: 14%;
    }
}

</style>