<template>
  <div class="container" @click="otherSelected">
    <AudioIcon />
    <h3 class="title">{{ title }}</h3>
    <div class="card">
      <div class="card-title">{{ cardTitle }}</div>
      <div class="card-content">
        <div class="mail-head">
          <img class="avatar" :src="avatarImg" alt="" />
          <div class="content">
            <div class="mail-title">{{ mailTitle }}</div>
            <div class="mail-sender">
              {{ mailSender[0] }}
              <span @click="mailSenderPlusClick">{{ mailSender[1] }}</span>
              {{ mailSender[2] }}
              <img class="plus" :src="mailSenderPlus" alt="" @click="mailSenderPlusClick" />
            </div>
          </div>
        </div>
        <!-- <CustomScrollbar :scrollUpdate="scrollUpdate"> -->
        <div class="mail-content" @scroll="handleScroll" @touchmove="handleScroll">
          <template v-for="(item, index) in mailText">
            <template v-if="isString(item)">
              <p v-if="index === 5" :key="index" @click="mailSurveyClick">{{ item }}<img class="plus"
                  :src="mailSurveyPlus" /></p>
              <p v-else-if="index === 9" :key="index" @click="mailAttachmentPlusClick">
                {{ item }}<img class="plus" :src="mailAttachmentPlus" />
              </p>
              <p v-else-if="index === 12" :key="index" @click="mailTipsPlusClick">{{ item }}<img class="plus"
                  :src="mailTipsPlus" /></p>
              <p v-else :key="index">{{ item }}</p>
            </template>
            <template v-else>
              <p :key="index" @click="mailSubmitPlusClick">
                {{ item[0] }}<span>{{ item[1] }}</span><img class="plus" :src="mailSubmitPlus" /><span>{{ item[2]
                  }}</span>{{ item[3] }}
              </p>
            </template>
          </template>
        </div>
        <!-- </CustomScrollbar> -->
      </div>
      <div :class="['button', isNoneSelected ? 'button-disable' : '']" @click="onSubmit">{{ buttonText }}
      </div>
    </div>
    <transition appear enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
      <Dialog v-if="isShowDialog" :isAllSelected="isAllSelected" :viewAnswer="onForward" />
    </transition>
  </div>
</template>

<script>
// import CustomScrollbar from '@game/Level02/components/CustomScrollbar.vue';
import Dialog from '@game/Level02/components/Dialog.vue';
import {
  tLevel2
} from '@game/utils';
import {
  audioMixinLevel1
} from '@game/mixins/audioMixin.js';

import avatar from '@game/assets/img/avatar.png';
import plusDisable from '@game/Level02/assets/img/plus-disable.png';
import plusEnable from '@game/Level02/assets/img/plus-enable.png';
import scrollSound from '@game/assets/media/level02/scroll.mp3';
import selectMailSound from '@game/assets/media/level02/select-mail.mp3';
import selectErrorSound from '@game/assets/media/level01/select-error.wav';

export default {
  name: 'Scene4',
  mixins: [audioMixinLevel1],
  components: {
    // CustomScrollbar,
    Dialog,
  },
  data() {
    return {
      title: tLevel2('scene4.title'),
      cardTitle: tLevel2('scene4.cardTitle'),
      mailTitle: tLevel2('scene4.mailTitle'),
      mailSender: tLevel2('scene4.mailSender'),
      mailSenderPlus: plusDisable,
      mailSurveyPlus: plusDisable,
      mailSubmitPlus: plusDisable,
      mailAttachmentPlus: plusDisable,
      mailTipsPlus: plusDisable,
      mailText: tLevel2('scene4.mailText'),
      buttonText: tLevel2('scene4.buttonText'),
      isShowDialog: false,
      avatarImg: avatar,
      debounceTimer: null,
    };
  },
  methods: {
    isString(value) {
      return typeof value === 'string';
    },
    mailSenderPlusClick(event) {
      event.stopPropagation();
      this.mailSenderPlus = this.mailSenderPlus === plusDisable ? plusEnable : plusDisable;
    },
    mailSurveyClick(event) {
      event.stopPropagation();
      this.mailSurveyPlus = this.mailSurveyPlus === plusDisable ? plusEnable : plusDisable;
    },
    mailSubmitPlusClick(event) {
      event.stopPropagation();
      this.mailSubmitPlus = this.mailSubmitPlus === plusDisable ? plusEnable : plusDisable;
    },
    mailAttachmentPlusClick(event) {
      event.stopPropagation();
      this.mailAttachmentPlus = this.mailAttachmentPlus === plusDisable ? plusEnable : plusDisable;
    },
    mailTipsPlusClick(event) {
      event.stopPropagation();
      this.mailTipsPlus = this.mailTipsPlus === plusDisable ? plusEnable : plusDisable;
    },
    otherSelected() { },
    onForward(event) {
      event.stopPropagation();
      this.$store.commit('completeTask')
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L2Scene5' })
      })
    },
    onSubmit(event) {
      event.stopPropagation();
      this.isShowDialog = true;
      if (this.isAllSelected) {
        this.$audioManager.play('other', selectMailSound);
      } else {
        this.$audioManager.play('other', selectErrorSound);
      }
    },
    scrollUpdate() {
      this.$audioManager.play('other', scrollSound);
    },
    handleScroll() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.$audioManager.play('other', scrollSound);
      }, 100); 
    },
  },
  computed: {
    isNoneSelected() {
      return this.mailSenderPlus === plusDisable &&
        this.mailSurveyPlus === plusDisable &&
        this.mailSubmitPlus === plusDisable &&
        this.mailAttachmentPlus === plusDisable &&
        this.mailTipsPlus === plusDisable;
    },
    isAllSelected() {
      return this.mailSenderPlus === plusEnable &&
        this.mailSurveyPlus === plusEnable &&
        this.mailSubmitPlus === plusEnable &&
        this.mailAttachmentPlus === plusEnable &&
        this.mailTipsPlus === plusEnable;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(@game/Level02/assets/img/mail-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    box-sizing: border-box;
    padding: .3rem .8rem;
    background-color: #66a261;
    border: .25rem solid #fff;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-top: 6%;
  }

  .card {
    box-sizing: border-box;
    margin-top: 8%;
    width: 90%;
    height: 80%;
    border-radius: 1.5rem;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    // padding-bottom: 10px;

    .plus {
      width: 1.2rem;
    }

    .card-title {
      background-color: #4a4a4a;
      min-height: 3rem;
      color: #d7cd81;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 2rem;
      // align-self: stretch;
      width: 100%;
      align-self: flex-start;
      // margin-bottom: min(1rem, 1%);
      margin-bottom: auto;
    }

    .card-content {
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // gap: 20px;
      padding: 15px 0;
      padding-right: 5px;
      height: 70%;

      .mail-head {
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        .avatar {
          width: 2.5rem;
        }

        .content {
          margin-left: .7rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .mail-title {
            font-size: .8rem;
            font-weight: bold;
            letter-spacing: 1px;
          }

          .mail-sender {
            font-size: .7rem;
            position: relative;

            span {
              cursor: pointer;
            }
          }

          .plus {
            position: absolute;
            right: -1.5rem;
            cursor: pointer;
          }
        }
      }

      .mail-content {
        // height: 180px;
        // max-height: 10%;
        max-height: 80%;
        padding: 1rem 1rem;
        padding-top: 0;
        font-weight: bold;
        letter-spacing: 1px;
        overflow-y: auto;

        p {
          font-size: .8rem;
          margin-top: 1rem;
          margin-left: .5rem;
          padding-right: .3rem;
        }

        p:first-child {
          margin-top: 0;
        }

        p:nth-child(6) {
          cursor: pointer;
        }

        p:nth-child(6) img,
        p:nth-child(9) img,
        p:nth-child(10) img,
        p:nth-child(13) img {
          vertical-align: bottom;
          cursor: pointer;
        }

        p:nth-child(9) span,
        p:nth-child(10) {
          cursor: pointer;
        }

        p:nth-child(13) {
          text-decoration: underline;
        }
      }
    }

    .button {
      margin-top: auto;
      margin-bottom: auto;
      box-sizing: border-box;
      background-color: #50a459;
      padding: .25rem .66rem;
      border-radius: .5rem;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }

    .button-disable {
      pointer-events: none;
      background-color: #d9d9d9;
      color: #000;
    }
  }
}
</style>