<template>
    <div class="container">
        <AudioIcon />
        <div class="container-text">
            <p>{{ text }}</p>
            <div class="form-container">
                <input type="text" v-model="password">
                <div class="button" @click="onClick">{{ buttonText }}</div>
            </div>
        </div>
        <transition-group appear name="animate__animated animate__bounce" tag="div" enter-active-class="animate__fadeIn"
            leave-active-class="animate__fadeOut">
            <div class="mask" v-if="isPasswordError" key="mask">
                <div class="dialog-container" key="dialog">
                    <div class="dialog">
                        <div class="dialog-text">{{ dialogText }}</div>
                        <div class="dialog-button" @click="isPasswordError = false">{{ dialogButtonText }}</div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import 'animate.css';
import { tLevel1 } from '@game/utils';
import warning from '@game/assets/media/level01/warning.mp3';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene21',
    mixins: [audioMixinLevel1],
    data() {
        return {
            password: '',
            text: tLevel1('scene21.text'),
            buttonText: tLevel1('scene21.buttonText'),
            tooltip: tLevel1('scene21.tooltip'),
            dialogText: tLevel1('scene21.dialogText'),
            dialogButtonText: tLevel1('scene21.dialogButtonText'),
            isPasswordError: false,
        }
    },
   methods: {
       onClick() {
           this.$audioManager.playButtonNext();
   
           // 使用正则表达式来验证密码是否符合条件
           const isValidPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#￥%@&*])[A-Za-z\d#￥%@&*]{8,16}$/.test(this.password);
   
           if (isValidPassword) {
               this.$store.commit('completeTask');
               this.$audioManager.playButtonNext(() => {
                   this.$router.push({ name: 'L1Scene22' });
               });
           } else {
               this.isPasswordError = true;
               this.$audioManager.play('error', warning);
           }
       }
   }
}

</script>

<style lang="scss" scoped>
.container {
    background-image: url(@game/Level01/assets/img/alex-1.jpg);

    .container-text {
        
        .form-container {
            width: 100%;
            display: flex;
            /* justify-content: space-between; */
            bottom: 4rem;
            right: 3rem;

            input,
            input:focus {
                border: none;
                border-bottom: 2px solid #5f857a;
                outline: none;
            }

            input {
                width: 11rem;
                font-size: 1rem;
            }

            .button {
                text-align: center;
            }
        }
    }

    .mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        .dialog-container {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translateX(-50%);
            
            
            .dialog {
                position: relative;
                width: 16rem;
                height: 16rem;
                background-image: url(@game/Level01/assets/img/weak-password-background.png);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                .dialog-text,
                .dialog-button {
                    position: absolute;
                    top: 10rem;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .dialog-text {
                    font-weight: bold;
                    font-size: 1rem;
                    color: #333a33;
                }

                .dialog-button {
                    text-align: center;
                    padding: 5px 15px;
                    top: 12.5rem;
                    background-color: #50a459;
                    color: #fff;
                    border-radius: .5rem;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
</style>