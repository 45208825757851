import happy from "../assets/media/happy.mp3";
import characterSelection from "../assets/media/character-selection-bg.mp3";
import l41Sound from "../assets/media/level04/bg1.mp3";
import l42Sound from "../assets/media/level04/bg2.wav";
import l5Sound from "../assets/media/level05/bg.mp3";
import openChestSound from "../assets/media/open-chest.mp3";

export const audioMixin1 = {
  mounted() {
    this.$store.commit("setCurrentAudio", { audioSrc: happy });
  },
};

export const audioMixinLevel1 = {
  mounted() {
    this.$store.commit("setCurrentAudio", { audioSrc: characterSelection });
  },
};

export const audioMixinLevel41 = {
  mounted() {
    this.$store.commit("setCurrentAudio", { audioSrc: l41Sound });
  },
};

export const audioMixinLevel42 = {
  mounted() {
    this.$store.commit("setCurrentAudio", { audioSrc: l42Sound });
  },
};

export const audioMixinLevel5 = {
  mounted() {
    this.$store.commit("setCurrentAudio", { audioSrc: l5Sound });
  },
};

export const audioMixinOpenChest = {
  mounted() {
    this.$store.commit("setCurrentAudio", { audioSrc: openChestSound });
  },
};
