<template>
  <div id="app">
    <!-- <GameContainer/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import GameContainer from './views/game/GameContainer.vue';
export default {
  name: 'App',
  // components: {
  //   GameContainer
  // }
}
</script>

<style scoped>
#app {
  display: flex;
  justify-content: center;
}
</style>
