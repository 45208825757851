<template>
  <div class="container">
    <div class="head">
      <div class="wrapper">
        <div class="time-bar">{{ secondsToHms(time) }}</div>
      </div>
      <div class="progress-bar">
        <div class="progress" :style="{ width: `${progress}%` }"></div>
      </div>
    </div>
    <div class="content">
      <h1 class="title">{{ title }}</h1>
      <div class="answer-wrapper">
        <p class="selectable" tabindex="0" @click="onSelectAnswer('a')">{{ questionObj.a }}
        </p>
        <p class="selectable" tabindex="0" @click="onSelectAnswer('b')">{{ questionObj.b }}
        </p>
        <p class="selectable" tabindex="0" @click="onSelectAnswer('c')">{{ questionObj.c }}
        </p>
      </div>
    </div>
    <transition appear enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
      <div class="overlay" v-show="dialogObj.isShow">
        <div class="dialog" :style="dialogStyle">
          <div class="title-bar" :style="titleBarStyle">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
          </div>
          <img :src="fileImg" alt="" class="mail-icon">
          <p>{{ dialogObj.text }}</p>
          <div class="button" @click="onForward">{{ dialogObj.buttonText }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { secondsToHms } from '../../utils';
import fileRed from '../../assets/img/dialog/file-red.png';
import fileYellow from '../../assets/img/dialog/file-yellow.png';

// import selectSound from '../../assets/media/level03/select.mp3';
import successSound from '../../assets/media/level03/success.mp3';
import warningSound from '../../assets/media/level03/warning.wav';

export default {
  name: 'QuestionnaireTemplate',
  props: {
    time: {
      type: Number,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    questionObj: {
      type: Object,
      required: true,
    },
    dialogObj: {
      type: Object,
      required: true,
    }
  },

  computed: {
    fileImg() {
      if (this.dialogObj.isCorrectAnswer) {
        return fileYellow
      }
      return fileRed
    },
    titleBarStyle() {
      if (this.dialogObj.isCorrectAnswer)
        return {
          backgroundColor: '#f9db5e',
        }
      else
        return {
          backgroundColor: '#b82e48',
        }
    },
    dialogStyle() {
      if (this.dialogObj.isCorrectAnswer)
        return {
          borderColor: '#f9db5e',
        }
      else
        return {
          borderColor: '#b82e48',
        }
    }
  },
  data() {
    return {
    }
  },
  watch: {
    'dialogObj.isShow': {
      handler(newVal) {
        if (newVal) {
          if (this.dialogObj.isCorrectAnswer) {
            this.$audioManager.play('other', successSound)
          } else {
            this.$audioManager.play('other', warningSound)
          }
        }
      },
      deep: true
    }
  },
  methods: {
    secondsToHms,
    onSelectAnswer(answer) {
      // this.$audioManager.playButtonNext(() => {
      //   this.questionObj.callback(answer)
      // }, selectSound)
      this.questionObj.callback(answer)
    },
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.dialogObj.forward()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/level03/scene-3-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  position: relative;
}

.head {
  margin-top: 3rem;
  width: 80%;
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .wrapper {
    margin-top: -1.66rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .time-bar,
    .score-bar {
      box-sizing: border-box;
      width: 40%;
      height: 1.75rem;
      border: .125rem solid #fff;
      background-color: #d9d9d9;
      border-radius: .75rem;
      display: flex;
      align-items: center;
      padding-left: .66rem;
      font-weight: bold;
      box-shadow: .125rem .26rem #a8c2cb;
      position: relative;
    }

    .time-bar {
      color: #50a458;
    }

    .score-bar {
      color: #e86124;
    }

    .time-bar::after,
    .score-bar:after {
      content: '';
      display: block;
      width: 2.1rem;
      height: 2.1rem;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: -.66rem;
      right: -.2rem;
    }

    .time-bar::after {
      background-image: url(../../assets/img/clock.png);
    }

    .score-bar::after {
      background-image: url(../../assets/img/diamond.png);
    }
  }

  .progress-bar {
    width: 100%;
    height: 1.5rem;
    border: .125rem solid #fff;
    background-color: #d9d9d9;
    border-radius: .75rem;
    box-shadow: .125rem .33rem #a8c2cb;
    position: relative;

    .progress {
      position: relative;
      width: 70%;
      height: 1.5rem;
      border-radius: .75rem;
      background-color: #66a261;
      z-index: 100;
      transition: all 1s ease-in;
    }

    .progress::after {
      content: '';
      display: block;
      width: 3rem;
      height: 3rem;
      background-image: url(../../assets/img/avatar.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      // top: -10px;
      // right: -5px;
      top: -.66rem;
      right: -.33rem;
    }
  }

  .progress-bar::after {
    z-index: 99;
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    background-image: url(../../assets/img/key.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: -.33rem;
    right: 0;
  }
}

.content {
  position: absolute;
  top: 32%;
  bottom: 8%;
  left: 1.5rem;
  width: 85%;
  box-sizing: border-box;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;


  .title {
    color: #fbfcfb;
    font-size: 1.2rem;
    padding: 0 1.5rem;
    margin: 0;
    font-weight: normal;
    text-align: center;
  }

  .answer-wrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;

    p {
      margin: 1% 0;
      flex: 1;
      box-sizing: border-box;
      background-color: #4f7a6c;
      color: #e4eae8;
      // min-height: 100px;
      border-radius: 1rem;
      padding: .33rem 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      cursor: pointer;
    }
  }

  .selectable:focus {
    background-color: #94afe8;
    outline: none;
  }
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($color: #fff, $alpha: .5);

  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    box-sizing: border-box;
    width: 80%;
    background-color: #fffdf8;
    border: .125rem solid #b82e48;
    border-radius: .66rem;
    overflow: hidden;
    gap: 1.66rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .title-bar {
      width: 100%;
      height: 1.8rem;
      background-color: #b82e48;

      display: flex;
      align-items: center;

      .circle {
        margin-left: 1rem;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #fff;
      }
    }

    .success {
      background-color: #b82e48;
    }

    .mail-icon {
      width: 5rem;
    }

    p {
      margin: 0;
      padding: 0 1.8rem;
      text-align: center;
    }

    .button {
      padding: .25rem .66rem;
      background-color: #66a261;
      border-radius: .66rem;
      color: #fff;
      margin-bottom: .66rem;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>