<template>
  <div class="container" @click="onForward">
    <AudioIcon />
    <p class="text">{{ text }}</p>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene15',
  mixins: [audioMixinLevel5],
  beforeCreate() {
    this.$store.commit('resetTask')
  },
  data() {
    return {
      text: tLevel5('scene15.text'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L5Scene16' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-4-bg.jpg);
  background-size: cover;
  ;
  background-repeat: no-repeat;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    width: 83%;
    box-sizing: border-box;
    border: 2px solid rgba(163, 255, 255, 1);
    color: #9cc8ee;
    background-color: rgba(0, 12, 74, .5);
    padding: 50px 35px;
    border-radius: 15px;
    margin: 0;
    margin-bottom: 50px;
  }

  &:hover {
    opacity: .8;
  }

}
</style>