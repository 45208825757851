<template>
  <div class="container">
    <AudioIcon />
    <h3 class="title">{{ title }}</h3>
    <div class="text-wrapper">
      <div class="wrapper">
        <img src="@game/Level04/assets/img/wannaCry1.png" alt="" class="wannaCry-img">
        <div class="wcry-container">
          <h4 class="wcry-title">{{ wcryTitle }}</h4>
          <p class="wcry-text">{{ wcryText }}</p>
        </div>
        <img src="@game/Level04/assets/img/ryuk.png" alt="" class="ryuk-img">
        <div class="ryuk-container">
          <h4 class="ryuk-title">{{ ryukTitle }}</h4>
          <p class="ryuk-text">{{ ryukText }}</p>
        </div>
      </div>
      <div class="button" @click="onClick">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import { tLevel4 } from '@game/utils';
import { audioMixinLevel42 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene7',
  mixins: [audioMixinLevel42],
  data() {
    return {
      title: tLevel4('scene6.title'),
      wcryTitle: tLevel4('scene7.wcryTitle'),
      wcryText: tLevel4('scene7.wcryText'),
      ryukTitle: tLevel4('scene7.ryukTitle'),
      ryukText: tLevel4('scene7.ryukText'),
      buttonText: tLevel4('scene6.buttonText'),
    }
  },
  methods: {
    onClick() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L4Scene8' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/Level04/assets/img/kepu.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3,
  h4,
  p,
  img {
    margin: 0;
    padding: 0;
  }

  .title {
    position: absolute;
    top: 3%;
    box-sizing: border-box;
    padding: 5px 15px;
    border: .2rem solid #fff;
    border-radius: 1.2rem;
    background: #66a261;
    color: #fff;
    font-weight: normal;
  }

  .text-wrapper {
    box-sizing: border-box;
    width: 70%;
    position: absolute;
    top: 15%;
    bottom: 15%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      gap: .5rem;
      width: 100%;

      .wcry-container,
      .ryuk-container {
        width: 100%;

        .wcry-title,
        .ryuk-title {
          color: #66a261;
        }
      }

      .wannaCry-img {
        height: 5rem;
      }

      .ryuk-img {
        height: 5rem;
      }
    }

    .button {
      margin-top: .5rem;
      background-color: #66a261;
      color: #fff;
      border-radius: .5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>