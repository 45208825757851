<template>
  <div class="container">
    <AudioIcon />
    <div class="text-container">
      <p>{{ text[0] }}<span>{{ text[1] }}</span>{{ text[2] }}</p>
      <div class="button" @click="onForward">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import { tLevel2 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene3',
  mixins: [audioMixinLevel1],
  data() {
    return {
      text: tLevel2('scene3.text'),
      buttonText: tLevel2('scene3.buttonText'),
    }
  },
  methods: {
    onBackward() {
      this.$audioManager.playButtonPrevious(() => {
        this.$router.go(-1)
      })
    },
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L2Scene4' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-image: url(@game/Level02/assets/img/alex-1.jpg);

  .text-container {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    p span {
      color: #7db079;
    }
    p{
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

    .button {
      margin-bottom: .5rem;
    }
  }
}
</style>