<template>
    <div class="container">
        <AudioIcon />
        <h3 class="title">{{ title }}</h3>
        <div class="wrapper">
            <p>{{ text }}</p>
            <div class="button" @click="onForward">{{ buttonText }}</div>
        </div>
    </div>
</template>

<script>
import { tLevel3 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
    name: 'Scene19',
    mixins: [audioMixinLevel1],
    beforeCreate() {
        this.$store.commit('resetTask')
    },
    data() {
        return {
            title: tLevel3('scene19.title'),
            text: tLevel3('scene19.text'),
            buttonText: tLevel3('scene4.buttonText'),
        }
    },
    methods: {
        onForward() {
            this.$audioManager.playButtonNext(() => {
                this.$router.push({ name: 'L3Scene20' })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    /* animation */
    position: absolute;

    width: 100%;
    height: 100%;
    background-image: url(@game/assets/img/level03/scene-19-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .title {
        position: absolute;
        top: 3%;
        left: 50%;
        transform: translateX(-50%);
        border: .22rem solid #fff;
        background-color: #66a261;
        color: #fff;
        border-radius: 1.25rem;
        box-shadow: .125rem .33rem #a8c2cb;
        padding: .33rem 1rem;
        min-width: 40%;
        text-align: center;
    }

    .wrapper {
        width: 72%;
        position: absolute;
        top: 45%;
        bottom: 18%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        p {
            margin: 0;
            padding: 0 10px;
            overflow-y: auto;
        }

        .button {
            box-sizing: border-box;
            background-color: #66a261;
            color: #fff;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }
    }
}
</style>