<template>
  <div class="container">
    <AudioIcon />
    <div class="dialog">
      <div class="head">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
      <div class="content">
        <div class="title-container">
          <img src="@game/assets/img/level05/avatar.png" alt="">
          <h3 class="title">{{ title }}</h3>
        </div>
        <p class="text">{{ text }}</p>
        <div class="button" @click="onForward">{{ buttonText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene1',
  mixins: [audioMixinLevel5],
  data() {
    return {
      title: tLevel5('scene2.title'),
      text: tLevel5('scene2.text'),
      buttonText: tLevel5('scene2.buttonText'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L5Scene3' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-2-bg.jpg);
  background-size: cover;
  ;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;


  .dialog {
    width: 83%;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 5px #c5cbb3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .head {
      width: 100%;
      height: 30px;
      background-color: #4a4a4a;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding-left: 30px;

      .circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 30px;

      .title-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        img {
          width: 40px;
          height: auto;
        }
      }

      .text {
        text-align: justify;
      }
    }
  }
}



.button {
  padding: 5px 10px;
  background-color: #66a261;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.button:hover {
  opacity: .8;
}
</style>