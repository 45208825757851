<template>
  <div class="container">
    <AudioIcon />
    <div class="text-container-scene-13">
      <img src="@game/Level02/assets/img/mail-icon.png" alt="" class="mail">
      <p>{{ text }}</p>
      <!-- <div class="button" @click="onForward">{{ buttonText }}</div> -->
      <img src="@game/assets/img/arrow.png" alt="" class="backward" @click="onBackward">
      <img src="@game/assets/img/arrow.png" alt="" class="forward" @click="onForward">
    </div>
  </div>
</template>

<script>
import { tLevel2 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene2',
  mixins: [audioMixinLevel1],
  data() {
    return {
      text: tLevel2('scene13.text'),
      buttonText: tLevel2('scene13.buttonText'),
    }
  },
  methods: {
    onBackward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.go(-1)
      })
    },
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L2Scene14' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/Level02/assets/img/game-over-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .text-container-scene-13 {
    width: 83%;
    margin-bottom: 4.5rem;
    padding-bottom: 1rem;
    background-color: rgba(255, 255, 255, .8);
    border: 2px solid #f4bd4d;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
      margin: 0;
      padding: 25px;
      padding-top: 40px;
    }

    .mail {
      position: absolute;
      width: 4rem;
      top: -2rem;
      right: 0;
    }

    .forward,
    .backward {
      position: absolute;
      right: -4%;
      bottom: -4%;
      width: 2.5rem;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }

    .forward {
      right: -4%;
    }

    .backward {
      left: -4%;
      transform: rotate(180deg);
    }

    .button {
      padding: .5rem 1rem;
      background-color: #66a261;
      border-radius: 1rem;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>