<template>
  <div class="container" @click="onClick">
    <div class="title">{{ title }}</div>
    <h1 class="level-name">{{ name }}</h1>
  </div>
</template>

<script>
export default {
  name: 'LevelStart',
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    forwardPath: {
      type: String,
      required: true,
    }
  },

  methods: {
    onClick() {
      // console.log(this.$i18n.locale, 1111111111111);
      this.$audioManager.playButtonNext(() => {
        this.$router.push(this.forwardPath)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/background.jpg);
  background-size: cover;
  ;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.container:hover {
  opacity: 0.8;
}

.container .title {
  // width: 7rem;
  // height: 2rem;
  // line-height: 2rem;
  box-sizing: border-box;
  padding: 3px 25px;
  border: 4px solid white;
  border-radius: 2rem;
  background-color: #50a459;
  color: white;
  font-size: 1.3rem;
  margin-top: 15.5rem;
}

img {
  width: 7rem;
  margin-top: 15.5rem;
}

.level-name {
  color: white;
  font-weight: normal;
  font-size: 2.2rem;
  margin-top: 2.6rem;
  letter-spacing: 0.1rem;
  text-align: center;

  &::before,
  &::after {
    content: ' - ';
    font-weight: bold;
  }
}
</style>