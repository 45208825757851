<template>
  <div class="container">
    <AudioIcon />
    <div class="text-container-l5-s1">
      <p>{{ text }}</p>
      <div class="button" @click="onForward">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import { tLevel5 } from '@game/utils';
import { audioMixinLevel5 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene1',
  mixins: [audioMixinLevel5],
  data() {
    return {
      text: tLevel5('scene1.text'),
      buttonText: tLevel5('scene1.buttonText'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L5Scene2' })
      })
    }
  }
}
</script>

<style scoped>
.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level05/scene-1-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.text-container-l5-s1 {
  width: 85%;
  margin-bottom: 10%;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p {
  margin: 0;
  padding: 25px;
  padding-bottom: 15px;
  text-align: justify;
}


.button {
  padding: 5px 10px;
  background-color: #66a261;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.button:hover {
  opacity: .8;
}
</style>