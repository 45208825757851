<template>
  <div class="container">
    <transition appear enter-active-class="animate__animated animate__fadeInLeft"
      leave-active-class="animate__animated animate__fadeOutRight">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import 'animate.css';
export default {
  name: 'GameContainer',
}
</script>

<style lang="scss" scoped>
.container {
  // min-width: calc(698 / 1480 * 100vh);
  // width: calc(698 / 1480 * 100vh);
  // height: 100vh;
  // min-width: calc(698 / 1480 * 800px);
  // width: calc(698 / 1480 * 800px);
  // height: 800px;
  /* max-height: 800px;
  overflow: hidden; */
  // margin-top: 4rem;
  width: 100vw;
  height: 100vh;
  font-family: 'hyt';
  color: #333a33;

  /* animation */
  position: relative;
}
</style>