<template>
    <div class="audio-icon" @click="handleClick"><img :src="audioIcon" alt=""></div>
</template>

<script>
import mute from '../assets/img/mute.png';
import turnOn from '../assets/img/turn-on.png';
// import happySound from '../assets/media/happy.mp3';
// import characterSelectionSound from '../assets/media/character-selection-bg.wav';
// import IamAlexSound from '../assets/media/i-am-alex.wav';

// const mapBgSound = {
//     GameHome: happySound,
//     CharacterSelection: characterSelectionSound,
//     CharacterProfile: IamAlexSound,
// }

export default {
    name: 'AudioIcon',
    props: {
        gameHome: {
            type: Boolean,
            default: false,
        },
        bgLoop: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        audioSrc() {
            return this.$store.state.audio.currentAudioSrc
        },
        isMuted() {
            return this.$store.state.audio.masterVolume === 0
        },
        audioIcon() {
            return this.isMuted ? mute : turnOn
        }
    },
    watch: {
        audioSrc(newSrc) {
            // if (newSrc) {
            if (!this.isMuted) {
                this.$audioManager.play('bg', newSrc, this.bgLoop);
            }
            // }
        }
    },
    mounted() {
        if (this.gameHome) {
            this.$audioManager.unlockAudioContext();
            this.$audioManager.unmuteAll();
            this.$store.commit('setMasterAudioVolume', 1)
            // this.$emit('audio-click')

            this.$audioManager.play('bg', this.$store.state.audio.currentAudioSrc, this.bgLoop);
        }
    },
    methods: {
        handleClick() {
            if (this.isMuted) {
                this.$audioManager.unlockAudioContext();
                this.$audioManager.unmuteAll();
                this.$store.commit('setMasterAudioVolume', 1)
                // this.$emit('audio-click')

                this.$audioManager.play('bg', this.$store.state.audio.currentAudioSrc, this.bgLoop);
            } else {
                this.$audioManager.muteAll();
                this.$store.commit('setMasterAudioVolume', 0)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.audio-icon {
    z-index: 100000;
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;

    img {
        width: 36px;
        height: auto;
    }

    &:hover {
        opacity: .8;
    }
}
</style>