<template>
  <div class="cover">
    <AudioIcon :gameHome="true" />
    <div class="animation">
      <div class="cloud">
        <LottieAnimation :animationData="cloud" />
      </div>
      <div class="cup">
        <LottieAnimation :animationData="cup" />
      </div>
      <div class="title">
        <LottieAnimation :animationData="titleAnimation" />
      </div>
    </div>
    <div class="button" @click="start">{{ $t('miniGame.startGame') }}</div>
  </div>
</template>

<script>
// import http from '../../http';
import LottieAnimation from './LottieAnimation.vue';
import cloud from './assets/animations/cloud.json';
import cup from './assets/animations/cup.json';
import titleAnimation from './assets/animations/title.json';
// import audioSrc from './assets/media/happy.mp3';

import { audioMixin1 } from './mixins/audioMixin.js';

export default {
  name: 'GameHome',
  mixins: [audioMixin1],
  components: {
    LottieAnimation
  },
  props: {
    msg: String
  },
  // mounted() {
  //   this.$i18n.locale = 'es'
  // },
  // mounted() {
  //   this.$store.commit('setCurrentAudio', { audioSrc });
  // },
  data() {
    return {
      cloud,
      cup,
      titleAnimation,
    }
  },


  methods: {
    start() {
      this.$audioManager.playButtonNext(async () => {
        // try {
        //   const data = await http.get('/user-level')
        //   const level = Math.min(data.level + 1, 5)
        //   for (let index = 1; index <= level; index++) {
        //     this.$store.dispatch('addLevel', index)
        //   }
        // } catch (error) {
        //   console.log(error)
        //   return
        // }
        this.$router.push({ name: 'CharacterSelection' })
      })
    },
    changeLanguage() {
      if (this.$i18n.locale === 'zh')
        this.$i18n.locale = 'es'
      else
        this.$i18n.locale = 'zh'
    }
  }
}
</script>

<style lang="scss" scoped>
.cover {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(./assets/img/cover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .animation {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .cloud {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .cup {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .title {
      // border: 1px solid red;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      // top: -150px;
      top: -18%;
      right: 0;
      bottom: 0;
      width: 85%;
    }
  }

  .button {
    max-width: 90%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 6%;
    padding: 10px 20px;
    background-color: #50a459;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 2rem;
    font-size: 2rem;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
