<template>
  <div class="container">
    <AudioIcon />
    <div class="text-container">
      <div class="wrapper">
        <p>{{ text[0] }}</p>
        <p>{{ text[1] }}</p>
        <p>{{ text[2] }}</p>
        <p>{{ text[3] }}</p>
      </div>
      <div class="button" @click="onForward">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import { tLevel3 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';

export default {
  name: 'Scene2',
  mixins: [audioMixinLevel1],
  data() {
    return {
      text: tLevel3('scene2.text'),
      buttonText: tLevel3('scene2.buttonText'),
    }
  },
  methods: {
    onForward() {
      this.$audioManager.playButtonNext(() => {
        this.$router.push({ name: 'L3Scene3' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.container {
  /* animation */
  position: absolute;

  width: 100%;
  height: 100%;
  background-image: url(@game/assets/img/level03/scene-2-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  .text-container {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    background-color: #e8fcfe;
    border-radius: 1rem;
    border: 2px solid #2760c7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0;

    .wrapper {
      padding: 1rem;
      padding-top: 1.5rem;

      p {
        margin: 0;
        padding: 0;

        &:nth-child(2) {
          margin: 1.3rem 0;
        }
      }
    }

    .button {
      background-color: #66a261;
      color: #fff;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>