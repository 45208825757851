<template>
    <div class="container">
        <AudioIcon />
        <div class="top">
            <img class="avatar" src="@game/Level01/assets/img/change-password-avatar.png" alt="">
            <div class="title">{{ title }}</div>
            <img class="password-img" src="@game/Level01/assets/img/password.png" alt="">
        </div>
        <div class="password-wrapper">
            <div class="password1" :class="{ selected: isPassword1Selected }" @click="onPassword1Click">a1234567b</div>
            <div class="password2" :class="{ selected: isPassword2Selected }" @click="onPassword2Click">password123
            </div>
        </div>
        <div class="container-text">
            <p>{{ text }}</p>
            <img src="@game/Level01/assets/img/lock.png" alt="">
        </div>
        <transition appear enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut">
            <PasswordErrorBox v-if="isDialogShow" :dialogTip="dialogTip" :buttonText="buttonText"
                :closeCallback="onCloseDialog" />
        </transition>
    </div>
</template>

<script>
import 'animate.css';
import { tLevel1 } from '@game/utils';
import { audioMixinLevel1 } from '@game/mixins/audioMixin.js';
import selectError from '@game/assets/media/level01/select-error.wav';
import PasswordErrorBox from '@game/Level01/components/PasswordErrorBox.vue';

export default {
    name: 'Scene9',
    mixins: [audioMixinLevel1],
    components: { PasswordErrorBox },
    data() {
        return {
            text: tLevel1('scene9.shylock'),
            title: tLevel1('scene9.title'),
            isPassword1Selected: false,
            isPassword2Selected: false,
            dialogTip: tLevel1('scene9.dialogTip'),
            buttonText: tLevel1('scene9.buttonTextConfirm'),
            buttonTextConfirm: tLevel1('scene9.buttonTextConfirm'),
            buttonTextView: tLevel1('scene9.buttonTextView'),
            isDialogShow: false,
        }
    },
    methods: {
        onPassword1Click() {
            this.isPassword1Selected = !this.isPassword1Selected
            if (this.isPassword1Selected && this.isPassword2Selected) {
                this.isDialogShow = true;
                this.buttonText = this.buttonTextView
                this.$audioManager.play('error', selectError)
            } else if (this.isPassword1Selected || this.isPassword2Selected) {
                this.isDialogShow = true;
                this.buttonText = this.buttonTextConfirm
                this.$audioManager.play('error', selectError)
            } else {
                this.isDialogShow = false;
            }
        },
        onPassword2Click() {
            this.isPassword2Selected = !this.isPassword2Selected
            if (this.isPassword1Selected && this.isPassword2Selected) {
                this.isDialogShow = true;
                this.buttonText = this.buttonTextView
                this.$audioManager.play('error', selectError)
            } else if (this.isPassword1Selected || this.isPassword2Selected) {
                this.isDialogShow = true;
                this.buttonText = this.buttonTextConfirm
                this.$audioManager.play('error', selectError)
            } else {
                this.isDialogShow = false;
            }
        },
        onCloseDialog() {
            this.$audioManager.playButtonNext()
            if (this.isPassword1Selected && this.isPassword2Selected) {
                this.$store.commit('completeTask')
                this.$router.push({ name: 'L1Scene10' })
            } else {
                this.isDialogShow = false
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.container {
    /* animation */
    position: absolute;

    width: 100%;
    height: 100%;

    background-image: url(@game/Level01/assets/img/change-password-bg.jpg);
    background-position: center;
    background-size: cover;
    ;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .top {
        position: absolute;
        bottom: 440px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .avatar {
            width: 4rem;
        }

        .title {
            color: #485e69;
            font-size: 1.3rem;
            // letter-spacing: .2rem;
        }

        .password-img {
            width: 60%;
        }
    }

    .password-wrapper {
        position: absolute;
        bottom: 270px;

        .password1,
        .password2 {
            cursor: pointer;
            border: 3px solid #f9db5e;
            background-color: #fff;
            width: 16rem;
            padding: 5px 10px;
            border-radius: .7rem;
            text-align: center;
            color: #424942;
            font-weight: bold;
            font-size: 1.2rem;

            &:hover {
                border-color: #c04539;
                background-color: #f4dedd;
            }
        }

        .password2 {
            margin-top: 20px;
        }

        .selected {
            border-color: #c04539;
            background-color: #f4dedd;
        }
    }

    .container-text {
        position: absolute;
        bottom: 50px;
        box-sizing: border-box;
        width: 83%;
        min-height: 110px;
        background-color: rgba(255, 255, 255, .5);
        border: 2px solid #E86124;
        border-radius: 1rem;
        padding: 20px 30px;
        padding-top: 40px;

        p {
            margin: 0;
        }

        img {
            width: 4rem;
            position: absolute;
            right: -0.2rem;
            top: -1.5rem;
        }
    }
}
</style>